:root {
  --primary-bg-color: #f5f5f5;
  --primary-text-color: #464646;
  --secondary-text-color: #696969;
  --underline-color: #422776;
}
.MuiTypography-root {
  font-family: "Inter", sans-serif !important;
}
.MuiTextField-root {
  font-family: "Inter", sans-serif !important;
}

body {
  background-color: var(--primary-bg-color) !important;
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif !important;
  color: var(--primary-text-color) !important;
}
.css-1wck5kf-MuiFormLabel-root-MuiInputLabel-root {
  font-family: "Inter", sans-serif !important;
  color: var(--primary-text-color) !important;
}
.css-1v2ereb-MuiButtonBase-root-MuiButton-root {
  font-family: "Inter", sans-serif !important;
}

span {
  font-family: "Inter", sans-serif !important;
}
.SRLCaptionContainer {
  display: none !important;
}

.SRLAutoplayButton {
  display: none !important;
}
.SRLDownloadButton {
  display: none !important;
}
.css-5lbw0b-MuiTypography-root {
  font-size: 24px !important;
  text-transform: uppercase !important;
  font-weight: 500 !important;
}
.MuiTypography-root MuiTypography-body1 css-ahj2mt-MuiTypography-root {
  font-size: 18px !important;
}

@media (max-width: 599.95px) {
  .jss13 {
    width: unset;
  }
}
.jss13 {
  width: unset;
}
.jss62{
  padding-left: 40px;
  padding-right: 40px;
}
.MuiGrid-spacing-xs-10{
  width: 100% !important;
  margin: unset !important;
}
.makeStyles-drawerPaper-13{
  width: 40% !important;
  max-width: 200px;
  min-width: 150px;
}