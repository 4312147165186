.logo {
  height: 50px;
}

.nav-link {
  color: var(--primary-text-color);
  text-decoration: none;
}
.nav-link:hover {
  border-bottom: 2px solid #422776;
  padding-bottom: 4px;
}

#home-link {
  text-decoration: none;
}

.MuiAppBar-colorPrimary {
  background-color: var(--primary-bg-color) !important;
  box-shadow: none !important;
}
.css-1t29gy6-MuiToolbar-root {
  min-height: 84px !important;
}

@media only screen and (max-width: 600px) {
  .MuiAppBar-root {
    flex-direction: row !important;
  }
  .logo {
    height: 50px;
  }
}
@media (max-width: 500px) {
  .logo {
    height: 35px;
  }
  .makeStyles-drawer-10 {
    width: 125px !important;
  }
}
